import CssBaseline from "@mui/material/CssBaseline";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Grid from "@mui/material/Grid";
import { Outlet } from "react-router-dom";

import { theme } from "./services/theme";
import { Link } from "./components/Link";
import { AppMenu } from "./AppMenu";

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ height: 100 }}></div>
      <Grid container spacing={2} style={{ justifyContent: "center" }}>
        <Grid container item xs={10} sm={6} md={6} lg={6} xl={4} spacing={2}>
          <Grid item xs={12}>
            <Link to="/" style={{ fontWeight: "bold" }}>
              Daskalakis Evangelos
            </Link>
          </Grid>
          <Grid item sm={12} md={4} lg={3}>
            <AppMenu />
          </Grid>
          <Grid item sm={12} md={8} lg={9} style={{ paddingBottom: 150 }}>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
