import { useState } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import IconButton from "@mui/material/IconButton";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";

export type GalleryProps = {
  items: React.ReactElement[];
};

export const Gallery: React.FC<GalleryProps> = ({ items }) => {
  const [state, setState] = useState({ currentImage: 0 });

  const changeImageBy = (value: number) => {
    const newCurrentImage = state.currentImage + value;
    if (newCurrentImage >= 0 && newCurrentImage < items.length) {
      setState((state) => ({ ...state, currentImage: newCurrentImage }));
    }
  };

  return (
    <Box style={{ width: "100%" }}>
      {items.map((item, index) => {
        if (index !== state.currentImage) {
          return null;
        }

        return (
          <Fade in={index === state.currentImage} key={index}>
            {item}
          </Fade>
        );
      })}
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div>
          <IconButton
            color="inherit"
            onClick={() => changeImageBy(-1)}
            disabled={state.currentImage <= 0}
          >
            <ArrowLeftIcon />
          </IconButton>
          <IconButton
            color="inherit"
            onClick={() => changeImageBy(1)}
            disabled={state.currentImage >= items.length - 1}
          >
            <ArrowRightIcon />
          </IconButton>
        </div>
      </div>
    </Box>
  );
};
