import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

import image from "./assets/contact.jpg";

export const Contact: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        Daskalakis Evangelos
        <br />
        Sifi Vlastou 16
        <br />
        74100 Rethymno, Crete
        <br />
        Greece
        <br />
        <br />
        <Link href="mailto:dask132@gmail.com" underline="none" color="inherit">
          dask132@gmail.com
        </Link>
        <br />
        <br />
        <Link
          href="https://www.instagram.com/evangelos.daskalakis/"
          target="_blank"
          underline="none"
          color="inherit"
        >
          <InstagramIcon />
        </Link>
        <Link
          href="https://facebook.com/evaggelos.delta"
          target="_blank"
          underline="none"
          color="inherit"
        >
          <FacebookIcon />
        </Link>
      </Grid>
      <Grid item md={6}>
        <img src={image} style={{ width: "100%" }} alt="" />
      </Grid>
    </Grid>
  );
};
