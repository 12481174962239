import { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { Gallery } from "../components/Gallery";

import image01 from "./assets/kuebiko/01.jpg";
import image02 from "./assets/kuebiko/02.jpg";
import image03 from "./assets/kuebiko/03.jpg";
import image04 from "./assets/kuebiko/04.jpg";
import image05 from "./assets/kuebiko/05.jpg";
import image06 from "./assets/kuebiko/06.jpg";
import image07 from "./assets/kuebiko/07.jpg";
import image08 from "./assets/kuebiko/08.jpg";
import image09 from "./assets/kuebiko/09.jpg";
import image10 from "./assets/kuebiko/10.jpg";
import { useLocation } from "react-router-dom";

const galleryItems = [
  <img src={image01} style={{ width: "100%" }} alt="" />,
  <img src={image02} style={{ width: "100%" }} alt="" />,
  <img src={image03} style={{ width: "100%" }} alt="" />,
  <img src={image04} style={{ width: "100%" }} alt="" />,
  <img src={image05} style={{ width: "100%" }} alt="" />,
  <img src={image06} style={{ width: "100%" }} alt="" />,
  <img src={image07} style={{ width: "100%" }} alt="" />,
  <img src={image08} style={{ width: "100%" }} alt="" />,
  <img src={image09} style={{ width: "100%" }} alt="" />,
  <img src={image10} style={{ width: "100%" }} alt="" />,
  <iframe
    width="100%"
    height="400"
    src="https://www.youtube-nocookie.com/embed/XApLS9Rg5Tg"
    title="Kuebiko by Evangelos Daskalakis"
    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    allowFullScreen
    style={{ borderStyle: "none", width: "100%" }}
  ></iframe>,
];

export const Kuebiko: React.FC = () => {
  const location = useLocation();

  if (location.hash === "#confirmed") {
    return (
      <Grid container spacing={2}>
        <Grid item md={12}>
          Your order had been confirmed.
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Gallery items={galleryItems} />
      </Grid>
      <Grid item md={12}>
        Dimensions 14,5cm * 20cm / 64 pages / Limited edition of 404 copies /
        Riso printing / Japanese folding / Recycled paper / Numbered and signed
        <br />
        <br />
        Developed and published in collaboration with Zoetrope Athens
        <br />
        <br />
        <strong>Statement</strong>
        <br />
        <br />
        In Japanese culture Kuebiko is the scarecrow. While it has full
        consciousness of what's happening around it, it has no other option but
        to stand still, having the role of the passive observer. The sole
        purpose of today's sociopolitical system is infinite growth, in an
        already finite environment. Year by year, we are exhausting the natural
        resources the earth can provide us with, and this process is ever
        accelerated. That means that we are mortgaging the future of the next
        generations and while simply we are part of a complicated chain, we are
        functioning arrogantly dominating the earth, undermining the effects of
        our interventions. As we know that we are heading to an impasse, we seem
        to be just observers, unable to turn things around. If we don't change
        our way of life, and if we don't redefine the concept of development, we
        will face “monsters” bigger than covid-19.
        <br />
        <br />
        <strong>Description text</strong>
        <br />
        <br />
        Evangelos Daskalakis' Kuebiko rises from the experience of the pandemic
        and captures the mnemonic trace of an Apocalypse. It is an ominous
        travelogue through a black and white urban landscape, almost void of
        life, basically in darkness. The city's empty streets are overtaken by
        the police and the army imposing curfew and control over the bodies of
        the Others. Employees in masks, even oxygen masks, in medical jumpsuits
        - faceless - patrol in large vehicles, and with their electronic
        thermometers in hand, sometimes with weapons.
        <br />
        <br />
        Daskalakis transforms his photographic documentation of a historical
        event into a graphic novel with cyberpunk elements, into a storyboard
        for a film noir, an eschatological prophecy about the future of
        humanity. The dystopia we are faced with permeates the pores of this
        book and those of its readers-spectators, awakening a very familiar
        nightmare: that of a withering world, drained by us.
        <br />
        <br />
        In Japanese mythology, the spirit "Kuebiko" symbolizes knowledge, and is
        depicted by a scarecrow: a motionless observer. Likewise, photography
        captures the world in stillness, bearing a dense meaning, a silent
        message.
        <br />
        <br />
        After all, the scarecrow can be a reflection of us humans. In the age of
        the Anthropocene and of technocapitalism, we are more immobilized than
        ever, passive observers of the terrifying ecological and political
        condition we live in. In the end, one wonders whether Kuebiko is a
        depiction of something past, a realistic projection of an inevitable
        future ahead of us, or a History whose next chapters we are called upon
        to write.
        <br />
        <br />
        - Pasqua Vorgia
        <br />
        <br />
        <br />
        Released at Polycopies book fair | Paris | FR | 11.2022
        <br />
        Presentation at ZoetropeAthens | Athens | GR | 11.2022
        <br />
        <br />
        <br />
        <OrderButton />
      </Grid>
    </Grid>
  );
};

export const OrderButton: React.FC = () => {
  const [state, setState] = useState<{ isOpen?: boolean }>({});

  if (!state.isOpen) {
    return (
      <Button
        variant="contained"
        onClick={() =>
          setState((state) => ({ ...state, isOpen: !state.isOpen }))
        }
      >
        Order the book
      </Button>
    );
  }

  return (
    <>
      <Button
        variant="contained"
        href="https://www.paypal.com/donate/?hosted_button_id=X7FXDR4B4J57Q"
      >
        International shipping
      </Button>{" "}
      <Button
        variant="contained"
        href="https://www.paypal.com/donate/?hosted_button_id=9MMB7JBUW6VEA"
      >
        Greece domestic shipping
      </Button>
    </>
  );
};
