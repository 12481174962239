import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { useLocation } from "react-router-dom";

import { Link } from "./components/Link";
import { useMemo, useState } from "react";
import { RouteMenuTitle, RouteName } from "./routes";

export const AppMenu: React.FC = () => {
  const [state, setState] = useState<{ isSubmenuOpen?: "projects" }>({});
  const onClickSubmenu = (submenu: "projects") => {
    setState((state) => ({
      ...state,
      isSubmenuOpen: state.isSubmenuOpen !== submenu ? submenu : undefined,
    }));
  };

  const location = useLocation();
  const currentRoute = useMemo(
    () =>
      Object.values(RouteName).find(
        (routeName) => location.pathname === `/${routeName}`
      ),
    [location.pathname]
  );

  if (currentRoute) {
    return <AppMenuRouteLink routeName={currentRoute} />;
  }

  return (
    <>
      {/* <AppMenuRouteLink routeName={RouteName.about} /> */}
      <Box>
        <Link to={"#"} onClick={() => onClickSubmenu("projects")}>
          Projects
        </Link>
        <Collapse
          in={state.isSubmenuOpen === "projects"}
          style={{ paddingLeft: 20 }}
        >
          <AppMenuRouteLink routeName={RouteName.kuebiko} />
          <AppMenuRouteLink routeName={RouteName.kawsaksacha} />
        </Collapse>
      </Box>
      <AppMenuRouteLink routeName={RouteName.contact} />
    </>
  );
};

export type AppMenuRouteLinkProps = {
  routeName: RouteName;
};

export const AppMenuRouteLink: React.FC<AppMenuRouteLinkProps> = ({
  routeName,
}) => {
  return (
    <Box>
      <Link to={"/" + routeName}>{RouteMenuTitle[routeName]}</Link>
    </Box>
  );
};
