import { createTheme } from "@mui/material/styles";
import "@fontsource/courier-prime";

export const theme = createTheme({
  typography: {
    fontFamily: "Courier Prime",
    button: { textTransform: "none" },
    fontSize: 12,
  },
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
  },
});

theme.typography.body1 = theme.typography.body2;
