export enum RouteName {
  about = "about",
  contact = "contact",
  kawsaksacha = "kawsaksacha",
  kuebiko = "kuebiko",
}

export const RouteMenuTitle = {
  [RouteName.about]: "About",
  [RouteName.contact]: "Contact",
  [RouteName.kawsaksacha]: "Kawsaksacha",
  [RouteName.kuebiko]: "Kuebiko",
};
