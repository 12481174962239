import React from "react";
import MaterialLink, {
  LinkProps as MaterialLinkProps,
} from "@mui/material/Link";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

export type LinkProps = React.PropsWithChildren<{
  to: RouterLinkProps["to"];
  style?: MaterialLinkProps["style"];
  onClick?: MaterialLinkProps["onClick"];
}>;

export const Link: React.FC<LinkProps> = ({ children, style, to, onClick }) => {
  return (
    <MaterialLink
      component={RouterLink}
      to={to}
      underline="none"
      color="inherit"
      style={style}
      onClick={onClick}
    >
      {children}
    </MaterialLink>
  );
};
