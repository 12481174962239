import Grid from "@mui/material/Grid";

import { Gallery } from "../components/Gallery";

import image01 from "./assets/kawsaksacha/01.jpg";
import image02 from "./assets/kawsaksacha/02.jpg";
import image03 from "./assets/kawsaksacha/03.jpg";
import image04 from "./assets/kawsaksacha/04.jpg";
import image05 from "./assets/kawsaksacha/05.jpg";
import image06 from "./assets/kawsaksacha/06.jpg";
import image07 from "./assets/kawsaksacha/07.jpg";
import image08 from "./assets/kawsaksacha/08.jpg";
import image09 from "./assets/kawsaksacha/09.jpg";
import image10 from "./assets/kawsaksacha/10.jpg";
import image11 from "./assets/kawsaksacha/11.jpg";
import image12 from "./assets/kawsaksacha/12.jpg";
import image13 from "./assets/kawsaksacha/13.jpg";
import image14 from "./assets/kawsaksacha/14.jpg";
import image15 from "./assets/kawsaksacha/15.jpg";
import image16 from "./assets/kawsaksacha/16.jpg";
import image17 from "./assets/kawsaksacha/17.jpg";
import image18 from "./assets/kawsaksacha/18.jpg";

const galleryItems = [
  <img src={image01} style={{ width: "100%" }} alt="" />,
  <img src={image02} style={{ width: "100%" }} alt="" />,
  <img src={image03} style={{ width: "100%" }} alt="" />,
  <img src={image04} style={{ width: "100%" }} alt="" />,
  <img src={image05} style={{ width: "100%" }} alt="" />,
  <img src={image06} style={{ width: "100%" }} alt="" />,
  <img src={image07} style={{ width: "100%" }} alt="" />,
  <img src={image08} style={{ width: "100%" }} alt="" />,
  <img src={image09} style={{ width: "100%" }} alt="" />,
  <img src={image10} style={{ width: "100%" }} alt="" />,
  <img src={image11} style={{ width: "100%" }} alt="" />,
  <img src={image12} style={{ width: "100%" }} alt="" />,
  <img src={image13} style={{ width: "100%" }} alt="" />,
  <img src={image14} style={{ width: "100%" }} alt="" />,
  <img src={image15} style={{ width: "100%" }} alt="" />,
  <img src={image16} style={{ width: "100%" }} alt="" />,
  <img src={image17} style={{ width: "100%" }} alt="" />,
  <img src={image18} style={{ width: "100%" }} alt="" />,
];

export const Kawsaksacha: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Gallery items={galleryItems} />
      </Grid>
      <Grid item md={12}>
        <strong>Kawsaksacha / The living Forest (ongoing)</strong>
      </Grid>
      <Grid item md={12}>
        A tropical forest in the Ecuadorian Amazonia. The indigenous Kichwas of
        the Sarayaku community (1200 people) lives in its depths. Their
        existence is part and parcel of the philosophy of “Kawsak Sacha” /
        living forest. “Kawsak Sacha” perceives the forest as a living being
        that is formed of and communicates with all the beings that live there:
        it's protecting spirits, animals, plants, trees, waterfalls, swamps,
        mountains, rivers.
        <br />
        <br />
        After a long and painful struggle against the Argentinean CGC petrol
        company and Ecuador's government, they managed to cancel the oil
        extraction project in their land. The community of Sarayaku proposes the
        introduction of the legal category of “Kawsak Sacha” as a protected
        zone, in which oil and mineral extraction, logging, and any other type
        of human extractive intervention, is prohibited.
        <br />
        <br />
        It's impressive how a society that looks poor at first glance, due to
        the lack of material goods, manages to interpret the notion of wealth
        differently; by prioritizing the natural world, communal life,
        simplicity, creativity, solidarity and happiness.
        <br />
        <br />
        ¡Allpamanda, causaimanda, jatarishún!” / For land, for life, rise up!
        <br />
        <br />
        Winning Series Decade of Change | British Journal of Photography 1854
        <br />
        Athens Photo Festival 2019 | Benaki Museum | GR
        <br />
        Jockey Club Museum of Climate Change | 2021 Hong Kong | HK
        <br />
        Nest summit, Javits Centre | 2021 New York | USA
        <br />
        Haimalina festival | 2022 Anogia Crete | GR
      </Grid>
    </Grid>
  );
};
